/*-----------------------------------------------------------------------------------*/
/*	HTML
/*-----------------------------------------------------------------------------------*/
body, html {  height: 100%; margin: 0; padding: 0; }

body {
  background: #F2F3F5;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  padding-top: 140px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  margin: 0;
  font-weight: 600;
}

h1 { font-size: 24px; line-height: 34px; }
h2 { 
  font-size: 20px;
  line-height: 30px;
  margin: 16px 0;
}
h3 { font-size: 18px; }
h4 { font-size: 16px; line-height: 24px; }

a { color: #ff3e97; }
a, a:hover, a:focus { outline: none; text-decoration: none; }
a:hover { color: #FE8AC7; }
a:focus { color: #ff3e97; }

hr {
  border-color: #E4E4E4;
  clear: both;
  float: none;
}
hr.dashed { border-style: dashed; }
hr.solid-pink { border-color: #FE8AC7; border-width: 3px; }
hr.no-margin { margin: 0; }
hr.no-margin-bottom { margin-bottom: 0; }

strong { font-weight: 500; }

label { font-weight: 500; }

.no-margin { margin: 0; }

.container.fullwidth { width: 100%; }

.text-light { font-weight: 300; }
.text-italic { font-style: italic; }
.text-bold { font-weight: 500; }
.text-success { color: #00BE9C; }

.btn.btn-transparent { background: transparent; }

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-item--stretch {
  align-self: stretch;
  -webkit-align-self: stretch;
}

.flex-item--center {
  align-self: center;
  -webkit-align-self: center;
}

.flex-item--end {
  align-self: flex-end;
  -webkit-align-self: flex-end;
}

/*-----------------------------------------------------------------------------------*/
/*	Navigation
/*-----------------------------------------------------------------------------------*/
.navbar-brand {
  height: 42px;
  padding: 5px 0 0;
}

.navbar-brand > span.logo {
  background: url('../../../../img/frontend/fastjobs-logo.png') no-repeat left top;
  background-size: cover;
  display: block;
  height: 42px;
  overflow: hidden;
  text-indent: -900px;
  width: 155px;
}

.navbar-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 55px;
}

.nav__wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.navbar-nav > li { position: relative; }

.navbar-default { background-color: #1f2c52; border-color: transparent; }

.navbar-default .navbar-nav > li > a, 
.navbar-default .navbar-nav > li > a:focus { color: #bec5d5; font-weight: 300; font-size: 14px; }

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:active { color: #fff; }

.navbar-default .navbar-nav > li.divider {
  border-right: 1px solid #415786;
  padding: 10px 0 3px;
  margin: 5px 15px 0;
}

.navbar-default .navbar-nav > .open > a, 
.navbar-default .navbar-nav > .open > a:hover, 
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .dropdown-menu > li > a:focus { background: none; color: #fff; }
.navbar-default .navbar-nav .dropdown-menu > li > a { color: #bec5d5; }
.navbar-default .navbar-nav .dropdown-menu { 
  background: #1f2c52;
  border-top: none;
  box-shadow: none;
  min-width: 220px;
  max-height: 420px;
  overflow-y: auto;
}
.navbar-default .navbar-nav .dropdown-header { color: #53618b; }
.navbar-default .navbar-nav .dropdown-menu .divider { background-color: #53618b; }
.navbar-default .navbar-nav .dropdown-toggle { padding-top: 10px; }

.navbar-default .navbar-nav .coy-logo {
  background-image: url('../../../../../img/frontend/default-logo-company.png');
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 65% auto;
  float: left;
  height: 40px; 
  width: 40px;
  margin: -9px 15px 0 0; 
  overflow: hidden;
  border-radius: 40px;
  -moz-border-radius: 40px;
  -webkit-border-radius: 40px;
}

.navbar > .navbar-main {
  background: #1f2c52;
  color: #bec5d5;
  min-height: 60px;
}

.navbar > .navbar-main .container { padding-right: 0; padding-top: 10px; }

.navbar > .navbar-main .btn {
  border-width: 2px;
  font-size: 16px;
  padding: 8px 15px;
}

.navbar-employer {
  background: #1f2c52;
  color: #BEC5D5;
  padding-bottom: 0;
  padding-top: 5px;
  position: fixed;
  transition: top 0.2s ease-in-out;
  top: 60px;
  width: 100%;
  z-index: 10;
  
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
}

.navbar-employer .navbar-nav > li { margin: 0 12px; }

.navbar-employer .navbar-nav > li > a {
  color: #BEC5D5;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 0;
  text-transform: uppercase;
}

.navbar-employer .navbar-nav > li.active > a { 
  color: #fff; 
  font-weight: 500; 
  border-bottom: 3px solid #ff8fca; 
}

.navbar-employer .navbar-nav > li > a:hover,
.navbar-employer .navbar-nav > li > a:active,
.navbar-employer .navbar-nav > li > a:focus,
.navbar-employer .navbar-nav > li.active > a:hover,
.navbar-employer .navbar-nav > li.active > a:focus { background: transparent; color: #fff; }

.navbar-employer .navbar-nav > li.nav-candidates-dropdown.open > a { background: transparent; }

.navbar-employer .navbar-nav > li span.label-highlighter {
  background: #FA4094;
  color: #fff;
  font-size:12px;
  font-weight: 400;
  line-height: 9px;
  padding: 3px 5px;
  position: absolute;
  right: 0;
  top: -5px;
  border-radius: 10px;
}

.navbar-employer .navbar-nav > li.menu-dropdown span.label-highlighter {
  right: 10px;
  top: 15px;
}

.label-zh,
.label-my,
.label-en {
  padding: 2px 4px;
  border-radius: 20px;
  margin-left: 10px;
}

.navbar-employer.for-mobile {
  position: fixed;
  top: 52px;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 1;
}

.navbar-employer.for-mobile.nav-up { top: -80px; }

.navbar-employer .container { padding-top: 0; }

.navbar-employer .dropdown-menu {
  background: #ecf0f3;
  border: none;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.176);
}

.navbar-employer .dropdown-menu > li > a {
  padding-bottom: 8px;
  padding-top: 8px;
}

.navbar-employer .dropdown-menu > li > a:hover {
  background: none;
  color: #ff3e97;
}

.navbar-employer .coins-balance-widget {
  font-size: 11px;
  padding: 7px 16px;
  bottom: 0;
  right: 0;
}

.navbar-employer .nav-support-link {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-employer .nav-support-link > div { margin-left: 8px; }

.navbar-employer .coins-balance-widget.urgent {
  background: #EF5350;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.25;
  padding: 8px 24px 8px 16px;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-animation:bgcolorchange 10s infinite alternate;
}

.navbar-header .fastemp-app-thumb {
  height: 50px;
  overflow: hidden;
  width: 73px;
  float: left;
  margin-left: 16px;
  margin: 0 4px 0 16px;
}

.navbar-header .fastemp-app {
  background: #304A97;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  padding: 6px 12px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}

.navbar-header .fastemp-app:hover {
  background: #FE8AC7;
  border-color: #f285bd;
  color: #fff !important;
  text-decoration: none;
}

.navbar-nav .contact-supp {
  
  background: #4B516F; 
  padding-top: 20px;
  padding-right: 10px;
  color: white;
  margin-top: -25px;
}

.navbar-nav .btn-contact-support {
  border: 1px solid #FFFFFF;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  background: #1f2c52;
  position: fixed;
  top: 335px;
  width: 92%;
}

.navbar-nav .clear-fix {
  margin-bottom: 65px !important;
}


@keyframes bgcolorchange {
  0% { background: #EF5350; }
  25% { background: #f76562; }
  50% { background: #bf3735; }
  75% { background: #f76562; }
  100% { background: #EF5350; }
}
@-webkit-keyframes bgcolorchange {
  0% { background: #EF5350; }
  25% { background: #ea6262; }
  50% { background: #bf3735; }
  75% { background: #ea6262; }
  100% { background: #EF5350; }
}

/*-----------------------------------------------------------------------------------*/
/*	Notification
/*-----------------------------------------------------------------------------------*/
.important-notice {
  background-color: #003863;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  padding: 20px 0 10px;
}
.important-notice .close {
  position: absolute;
  top: 5px;
  right: 10px;
  color: #fff !important;
  opacity: 100%;
}
.important-notice p > strong {
  font-weight: 700;
}

/*-----------------------------------------------------------------------------------*/
/*	Navigation Tabs
/*-----------------------------------------------------------------------------------*/
.nav-tabs { border-color: #CED3E0; }

.nav-tabs > li > a {
  background-color: #fff;
  border-color: #CED3E0;
  color: #1F2C52;
  text-transform: uppercase;
}

.nav-tabs > li > a:hover {
  background-color: #ECF0F3;
  border-color: #CED3E0; 
}

.nav-tabs > li.active > a, 
.nav-tabs > li.active > a:hover, 
.nav-tabs > li.active > a:focus { 
  background-color: #F6F8FB;
  border-color: #CED3E0; 
  border-bottom-color: transparent;
  color: #1F659B;
  font-weight: 500;
}

/*-----------------------------------------------------------------------------------*/
/*	Tab pill
/*-----------------------------------------------------------------------------------*/
.pill-item { border-color: #CED3E0; }

.pill-item:hover,
.pill-item:focus { background: #FE8AC7; border-color: #f285bd; }

.pill-item > a {
  color: #343e4e;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.pill-item > a:hover,
.pill-item > a:focus {
  color: #fff;
  text-decoration: none;
}

.pill-item.active { background: #343e4e; border-color: #2d3949; }

.pill-item.active:hover,
.pill-item.active:focus { background: #343e4e; border-color: #2d3949; }

.pill-item.active > a,
.pill-item.active > a:hover,
.pill-item.active > a:focus { color: #fff; border-color: #2d3949; }

/*-----------------------------------------------------------------------------------*/
/*	Content
/*-----------------------------------------------------------------------------------*/
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.page-header {
  border-color: #CED3E0;
  margin: 10px 0;
  padding-bottom: 5px;
}

.clear {
  clear: both;
  float: none;
}

.page-title {
  border-bottom: 1px solid #ced3e0;
  font-weight: 600;
  margin: 30px 0 20px;
  padding: 0 0 15px;
}

.page-title.page-title-job {
  font-size: 24px;
  color: #18181B;
  font-weight: 600;
  line-height: 28px;
  font-family: "Poppins";
}

.page-title-job .pull-left {
  display: flex;
  align-items: center;
  line-height: 32px;
}

@media (max-width: 639px) {
  .page-title.clearfix .pull-right {
    width: 100%;
    margin-top: 12px;
  }

  .page-title.clearfix .pull-right a.btn-primary {
    width: 100%;
  }
}

/*-----------------------------------------------------------------------------------*/
/*	Buttons
/*-----------------------------------------------------------------------------------*/
.btn {
  font-weight: 500;
  padding: 8px 16px;
  outline: none;
}

.btn-sm { padding: 5px 10px; font-size: 14px; }
.btn-xs { padding: 1px 6px; }

.btn-primary {
  background-color: #ff3e97;
  border-color: #ed3a8c;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #ed3a8c;
  border-color: #ff3e97;
}

a.btn-primary { color: #fff !important; }
a.btn-primary:hover,
a.btn-primary:focus {
  background: #DE136E !important;
  border-color: #FA4094 !important;
}

.clearfix .pull-right a.btn-primary {
  color: #FA4094 !important;
  background-color: #fff;
  border: 1.3px solid #FA4094;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  line-height: 19.2px;
  font-family: "lato";
  width: 168px;
}

.btn-secondary {
  background-color: #263F88;
  border-color: #2d4ea8;
  color: #fff;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background: #2d4ea8;
  border-color: #263F88;
  color: #fff;
}

a.btn-secondary { color: #fff !important; }
a.btn-secondary:hover,
a.btn-secondary:focus {
  background: #2d4ea8 !important;
  border-color: #263F88 !important;
}

.btn-default {
  background-color: #fff;
  border-color: #d6dbe4;
  color: #1f2c52;
}

.btn-default:hover,
.btn-default:focus {
  background-color: #FE8AC7;
  border-color: #f285bd;
  color: #fff;
}

.btn-default > .glyphicon,
.btn-default > .fa { color: #ff3e97; }
.btn-default:hover > .glyphicon,
.btn-default:hover > .fa,
.btn-default:focus > .glyphicon,
.btn-default:focus > .fa { color: #fff; }

.input-group-btn > .btn-default { padding: 6px 16px; }

.btn.disabled,
.btn-default.disabled,
.btn-default.disabled > .glyphicon,
.btn-default.disabled > .fa,
.btn-default.disabled:hover, 
.btn-default.disabled:focus { background-color: #f9f9f9; color: #ccc; }

.btn-primary.disabled, 
.btn-primary[disabled], 
fieldset[disabled] .btn-primary, 
.btn-primary.disabled:hover, 
.btn-primary[disabled]:hover, 
fieldset[disabled] .btn-primary:hover, 
.btn-primary.disabled:focus, 
.btn-primary[disabled]:focus, 
fieldset[disabled] .btn-primary:focus, 
.btn-primary.disabled:active, 
.btn-primary[disabled]:active, 
fieldset[disabled] .btn-primary:active, 
.btn-primary.disabled.active, 
.btn-primary[disabled].active, 
fieldset[disabled] .btn-primary.active {
  background-color: rgba(255, 62, 151, .65);
  border-color: transparent; 
  color: #fff;
}

.btn-default.disabled:hover, 
.btn-default[disabled]:hover, 
fieldset[disabled] .btn-default:hover, 
.btn-default.disabled:focus, 
.btn-default[disabled]:focus, 
fieldset[disabled] .btn-default:focus, 
.btn-default.disabled.focus, 
.btn-default[disabled].focus, 
fieldset[disabled] .btn-default.focus {
  color: #333;
}

.input-group-lg > .form-control, 
.input-group-lg > .input-group-addon, 
.input-group-lg > .input-group-btn > .btn { font-size: 16px; }
.input-group-lg > .form-control.small-text { font-size: 14px; }

.login-password {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #CCC;
  border-radius: 0 4px 4px 0;
}

.login-password:focus-within {
  outline: #66AFE9;
  border-color: #66AFE9;
}

#login-form #loginform-username {
  box-shadow: none;
}

.login-password #loginform-password {
  outline: none!important;
  border: none;
  box-shadow: none;
  border-right: 1px solid #CCC;
}

.login-password .password-toggle {
  cursor: pointer;
  margin: 10px;
  color: #CCC;
}

.password-wrapper {
  margin-bottom: 5px;
}

.modal-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
/*-----------------------------------------------------------------------------------*/
/*	Modal: Left or Right Positions
/*-----------------------------------------------------------------------------------*/

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 480px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
     -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body { padding: 15px 15px 80px; }

.modal.left.fade .modal-dialog {
  left: -480px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog { left: 0; }

.modal.right.fade .modal-dialog {
  right: -480px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog { right: 0; }

.modal-content {
  border-radius: 0;
  border: none;
}

.modal-rounded .modal-content {
  -webkit-border-radius: 3px; 
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.modal-header {
  border-bottom-color: #EEEEEE;
  background-color: #FAFAFA;
}

.modal.left .modal-header,
.modal.right .modal-header {
  background: #2D3949;
  border-bottom: none;
  color: #fff;
  height: 105px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.modal.left .modal-header h2,
.modal.left .modal-header .close,
.modal.right .modal-header h2,
.modal.right .modal-header .close { color: #fff; }

.modal-sub-header {
  border-bottom: solid 1px #EEEEEE;
  background-color: #FAFAFA;
  padding: 10px;
}

.modal-body > .modal-body-empty {
  color: #5F5F5F;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.modal-body > .modal-body-empty > i {
  color: #BEBEBE;
  display: block;
  font-size: 128px;
  margin-bottom: 20px;
}

.modal-body > .modal-loading {
  position: fixed;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.modal-body > .modal-loading-full {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 11;
}

.modal-body > .modal-loading-full > img {
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
}

.modal-body > .modal-loading-full > span {
  display: block;
  color: #fff;
  top: 0;
  bottom: 0;
  height: 120px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}

.modal-body-content { padding-top: 110px; }

.modal-body .modal-form-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 1px solid #D6DBE4;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  background: #fff;
}

/*-----------------------------------------------------------------------------------*/
/*	Modal: Offer to buy coin plans
/*-----------------------------------------------------------------------------------*/
.modal-buy-coins { 
  top: 10%;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-style: normal;
}

.modal-buy-coins .modal-dialog { width: 451px; }

.modal-buy-coins .modal-body {
  font-size: 16px;
  padding: 30px;
  text-align: center;
}

.modal-buy-coins a.close {
  opacity: unset !important;
  margin: -15px -15px 0 0;
}

.modal-buy-coins h1 { 
  font-family: 'Poppins', system-ui, Arial, sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 30px; 
}

.modal-buy-coins .offer-plan { margin-top: 10px; text-align: left; }

.modal-buy-coins .package-discount {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #FA4094;
  color: #fff;
  padding: 5px 20px;
  border-bottom-right-radius: 20px;

}

.modal-buy-coins .coin-offer-price {
  color: #ff3e97;
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
}

.modal-buy-coins .coin-offer-price-note {
  color: #777;
  font-size: 12px;
}

.modal-buy-coins .package-coins { 
  font-family: inherit;
  line-height: 40px;
}

.modal-buy-coins .package-promo { 
  color: #FA4094;
  font-size: 13px;
}

.modal-buy-coins .package-content {  line-height: 40px; }
.modal-buy-coins .btn.btn-default:hover{ background: #FFFFFF !important;}
.modal-buy-coins .btn.btn-default { color: #FA4094 !important; }

/*-----------------------------------------------------------------------------------*/
/*	Table
/*-----------------------------------------------------------------------------------*/

.table > thead > tr > th {
  background-color: #303E4C;
  border: 1px solid #272F39 !important;
  color: #fff;
  font-weight: 400;
}

.table > thead > tr > th > a { color: #fff; }

.table-striped > tbody > tr > td, 
.table-striped > tbody > tr > th { background-color: #F9F9F9; }

.table-striped > tbody > tr:nth-child(2n+1) > td, 
.table-striped > tbody > tr:nth-child(2n+1) > th { background-color: #fff; }

/*-----------------------------------------------------------------------------------*/
/*	Pagination
/*-----------------------------------------------------------------------------------*/

.pagination > li > a,
.pagination > li > span {
  border-color: #d6dbe4;
  padding: 10px 20px;
}

.pagination > .active > a, 
.pagination > .active > span, 
.pagination > .active > a:hover, .pagination > .active > span:hover, 
.pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #303e4c;
  border-color: #d6dbe4;
}

/*-----------------------------------------------------------------------------------*/
/*	Navigation Pills
/*-----------------------------------------------------------------------------------*/
.nav-pills > li { margin: 0 10px; }
.nav-pills > li > a {
  background: #f4f7fa;
  color: #343e4e;
  padding-left: 25px;
  padding-right: 25px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
}

.nav-pills > li > a:focus,
.nav-pills > li > a:hover {
  background: #FE8AC7;
  border-color: #f285bd;
  color: #fff;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  background: #2d3949;
  border-color: #2d3949;
  color: #fff;
}

/*-----------------------------------------------------------------------------------*/
/*	Forms: Custom Checkbox & Radio Button
/*-----------------------------------------------------------------------------------*/
.control-group {
  display: inline-block;
  vertical-align: top;
  background: #fff;
  text-align: left;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  padding: 30px;
  width: 200px;
  height: 210px;
  margin: 10px;
}

.control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control__indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background: #fff;
  border: 1px solid #d6dbe4;
}

.control--checkbox .control__indicator { -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px; }
.control--radio .control__indicator { border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator { border-color: #ff3e97; }

.control input:checked ~ .control__indicator { border-color: #ff3e97; }

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator { }

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  border-color: #d6dbe4;
  opacity: 0.6;
  pointer-events: none;
}

.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.control input:checked ~ .control__indicator:after { display: block; }

.control--checkbox .control__indicator:after {
  left: 8px;
  top: 3px;
  width: 6px;
  height: 14px;
  border: solid #ff3e97;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control--checkbox input:disabled ~ .control__indicator:after { border-color: #7b7b7b; }

.control--radio .control__indicator:after {
  left: 5px;
  top: 5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ff3e97;
}
.control--radio input:disabled ~ .control__indicator:after { background: #7b7b7b; }

.control--sm {
  font-size: 14px;
  line-height: 20px;
  padding-left: 26px;
}

.control--sm .control__indicator {
  height: 20px;
  width: 20px;
}

.control--sm.control--checkbox .control__indicator:after {
  height: 12px;
  left: 6px;
  top: 2px;
}

.control--sm.control--radio .control__indicator:after {
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
}

/*-----------------------------------------------------------------------------------*/
/*	Messages
/*-----------------------------------------------------------------------------------*/
.navbar-nav .nav-msg .unread-msg-count {
  background: #ff3e97;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  top: -4px;
  right: 0;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.iziToast.new-msg-toast > .iziToast-body > .iziToast-icon { top: 25%; }
.iziToast.new-msg-toast > .iziToast-body > .iziToast-buttons { display: block; }

/*-----------------------------------------------------------------------------------*/
/*	Animate
/*-----------------------------------------------------------------------------------*/

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  transform-origin: top center;
  animation-name: swing;
}

@keyframes wobble {
  from {
    transform: none;
  }

  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: none;
  }
}

.wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: none;
  }

  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  animation-name: jello;
  transform-origin: center;
}

@-webkit-keyframes uparrow {
  0% { -webkit-transform: translateY(0); opacity: 0.4 }
  100% { -webkit-transform: translateY(-0.4em); opacity: 0.9 }
}

@media (max-width: 1101px ) and (min-width: 1037px) {
  .navbar-nav .contact-supp {
    background: #4B516F; 
    padding-top: 20px;
    padding-right: 10px;
    color: white;
    margin-top: -25px;
  }
  .navbar-nav .coy-name {
    position: absolute;
    right: 10px;
    width: 100%;
  }
  .navbar-nav .admin-name {
    position: absolute;
    right: 200px;
    width: 60%;
  }
}

@media (max-width: 1036px ) and (min-width: 768px) {
  .navbar-nav .coy-name {
    position: absolute;
    right: 150px;
    width: 60%;
  }
  .navbar-nav .admin-name {
    position: absolute;
    right: 280px;
    width: 60%;
  }
}


/*-----------------------------------------------------------------------------------*/
/*	Responsive: Tablet Devices
/*-----------------------------------------------------------------------------------*/
@media (max-width: 991px) and (min-width: 768px) {
  .container { width: 94%; }

  .btn {
    font-size: 12px;
    padding: 8px;
  }
  .btn-sm { padding: 5px 10px; }
  .btn-xs { padding: 1px 6px; }
  .input-group-btn > .btn-default { padding: 7px 16px; }
  
  .messages-tooltip-holder.unread { left: 63%; }
  
  .sidebar { width: 25%; }
}


/*-----------------------------------------------------------------------------------*/
/*	Responsive: Mobile Devices
/*-----------------------------------------------------------------------------------*/

@media (max-width: 767px) {
  body { padding-top: 80px; }

  #tourBackdrop {
    position: unset !important;
  }

  .btn {
    font-size: 12px;
    padding: 8px;
  }
  .modal.left .modal-dialog,
  .modal.right .modal-dialog { width: 100%; }
  .modal.left.fade .modal-dialog { left: 0; }
  .modal.right.fade .modal-dialog { right: 0; }
  
  .navbar-toggle.navbar-mobile:focus,
  .navbar-toggle.navbar-mobile:hover { background: #fa3a90; }
  
  .navbar > .navbar-main .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .navbar-brand > span.logo,
  .navbar-brand-middle > span.logo {
    background: url('../../../../img/frontend/fastjobs-logo.png') no-repeat left top;
    background-size: cover;
    display: block;
    height: 28px;
    overflow: hidden;
    text-indent: -900px;
    width: 105px;
  }

  .navbar-brand > span.logo-icon {
    background: url('../../../../img/frontend/fastjobs-logo-icon.png') no-repeat left top;
    background-size: cover;
    display: block;
    height: 36px;
    margin: -6px 10px 0;
    overflow: hidden;
    text-indent: -900px;
    width: 32px;
  }
  
  .navbar-default {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
    -moz-box-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);
  }
  
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #bec5d5;
    font-size: 15px;
    padding-left: 56px;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus { color: #fff; }
  
  .navbar-default .navbar-toggle {
    border-color: #ff3e97;
    border-width: 2px;
    color: #fff;
    float: right;
    margin: 0 10px 0;
    padding: 5px 10px;
  }

  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus { background: none; }
  
  .navbar-default .navbar-nav > .active > a, 
  .navbar-default .navbar-nav > .active > a:hover, 
  .navbar-default .navbar-nav > .active > a:focus { background: none; color: #fff; }
  
  .navbar-default .navbar-collapse, 
  .navbar-default .navbar-form { border: none; }
  
  .navbar-default .navbar-nav > li.divider {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin: 5px 0;
    padding: 0;
  }

  .btn-menu { color: #fff; }
  
  .messages-tooltip-holder {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    z-index: 1200;
  }
  
  .messages-tooltip-holder.unread {
    left: 0;
    top: 0;
    height: auto;
    padding: 40px 0;
    width: 100%;
    z-index: 1200;
  }

  .modal-buy-coins .modal-dialog { width: auto; }
  .modal-buy-coins h1 { font-size: 24px; margin-bottom: 15px; }
  .modal-buy-coins a.close { margin: -20px -20px 0 0; }
  .modal-buy-coins .offer-plan { margin-top: 0; text-align: center; }
  .modal-buy-coins .coin-offer-price { font-size: 28px; }
  .modal-buy-coins .btn { font-size: 16px; }

  .login-wrapper {
    margin-top: 30px;
  }
  
}

/*-----------------------------------------------------------------------------------*/
/*	CCOTG Dashboard
/*-----------------------------------------------------------------------------------*/
body.ccotg-dashboard { padding-top: 80px; }

.ccotg-dashboard .job-page-header { top: 60px; }
.ccotg-dashboard .job-page-sidebar { 
    top: 80px; 
    height: calc(100% - 80px);
}


/*-----------------------------------------------------------------------------------*/
/*	Job Stat & Action 
/*-----------------------------------------------------------------------------------*/
#jobsList .panel-body{
  padding: 30px !important;
}

#jobsList .fast-button {
  color: #FFFFFF;
}

#jobsList .job-ad .fast-button:hover,
#jobsList .job-ad .fast-button:active,
.jobad-sidebar > li:first-child a:hover {
  background-color: #DE136E !important;
}

#jobsList .fast-button[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

#jobsList .fast-button[class*=btn-outline] {
  border-style: solid;
  border-width: 1px;
}

#jobsList .fast-button.btn-outline-primary {
  background-color: transparent;
  border-color: #8A91AF;
  color: #DE136E;
}

#jobsList .fast-button.btn-outline-primary:hover,
#jobsList .fast-button.btn-outline-primary:active {
  background-color: #F4F4F4 !important;
}

.list-unstyled.job-stats {
  margin-bottom: 0;
}

.job-stats
{
  display: flex;
  gap: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.job-stats > li {
  cursor: pointer;
}

.job-stats > li > a {
  color: #000000;
  display: flex;
  flex-direction: column;
}

.job-stats .stat-number {
  font-family: 'Poppins', system-ui, Arial, sans-serif!important;
  font-weight: 500;
  font-size: 20px;
  padding: 16px 40px;
  background: #FAFAFA;
  color: #3F3F46;
}

.job-stats .stat-label {
  font-family: 'lato', system-ui, Arial, sans-serif!important;
  font-weight: 400;
  font-size: 12px;
  color: #3F3F46;
}

.btn-view-stats.btn-chart-bar {
  padding-top: 24px;
}

.job-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 30px;
}

.job-ad .job-tags {
  display: flex;
  margin-bottom: 10px;
  gap: 8px;
}

.job-ad .job-tags .tag {
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 4px 8px;
}

.job-ad .job-tag {
  background: #F5F5F5;
  color: #3F3F46;
  font-size: 12px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  cursor: default;
}

.job-ad .job-salary-location {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 8px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  color: #3F3F46;
}

.job-ad .job-salary,
.job-ad .job-location {
  display: flex;
  align-items: center;
  gap: 8px;
}

.job-ad .job-info-link {
  font-size: 14px;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  color: #71717A!important;
  display: flex;
  flex-direction: column;
}

.job-ad .job-info-label {
  font-weight: 700;
}

.job-info-label[data-state="hide"] {
  display: none;
}

.job-ad .job-info-data {
  font-weight: 400;
}

.job-stats-link {
  display: flex;
  align-items: center;
  gap: 7px;
}

.job-actions-item .job-action-link {
  appearance: none;
  background-color: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 14px;
  height: 42px;
  width: 100%;
  gap: 12px;
  font-size: 16px;
  color: #3F3F46;
  font-weight: 700;
}

.job-actions-item .job-share {
  width: 100%;
  
  > .dropdown-toggle {
    width: 100%;
  }
  
  [slot="content"] {
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
  }
  
  .dropdown-item {
    appearance: none;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    gap: 8px;
    width: 100%;
  }
}

.job-actions-item .job-item-color,
.block-sidebar-items .job-item-color {
  color: #18181B;
}

.job-actions-item .job-action-link svg path {
  fill: currentColor;
}

.bump-text-info {
  color: #3F3F46;
}

.jobad-sidebar .job-actions-item .bump-text-info {
  position: absolute;
  right: 10px;
  align-items: center;
  display: flex;
}

.jobad-actions > li.job-actions-item:not(:first-child):hover {
  background-color: #F5F5F5;
}

.job-ad-title {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px * 1.6;
}

.my-job-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 40px;
  background: #FAFAFA;
  color: #3F3F46;
  border-radius: 4px;
}

.my-stat-label {
  font-size: 12px;
}

/* Modal */
.modal-wrapper {
  padding: 20px;
  overflow-wrap: break-word;
}

.modal-active-title {
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.modal-active-desc {
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: #333;
}

.modal-active-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.modal-active-buttons button{
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-size: 16px;
}

.modal-active-submit {
  background-color: #FA4094;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.modal-active-submit:hover,
.modal-active-submit:active {
  background-color: #DE116E;
}

.modal-active-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.modal-active-cancel {
  background-color: transparent;
  color: #FA4094;
}

.modal-active-cancel:hover,
.modal-active-cancel:active {
  background-color: #FFEEF6;
  color: #FA4094;
}

.modal-active-cancel:disabled {
  opacity: 0.4;
}

@media(max-width: 767px) {
  .job-ad div {
    float: none;
  }
}

@media (max-width: 650px) {
  .job-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .job-actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

}

/* Parking lots */
.parking-slot-available {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.parking-slot-available svg path {
  fill: #FFF;
}

.parking-slot-available span {
  color: #FFF;
  font-family: 'Lato', system-ui, Arial, sans-serif;
}

/* Navigation Menu */
.header-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  row-gap: 15px;
  flex-wrap: wrap;
}

.header-slot-available {
  display: flex;
  align-items: flex-end;
  column-gap: 8px;
  padding: 7px 16px;
  border-radius: 4px 0px 0px 0px;
  color: #FFF;
}

.slot-available-mobile {
  padding: 12px 16px;
  display: flex!important;
  align-items: center;
  column-gap: 8px;
  justify-content: flex-start;
}

.header-slot-available-warning,
.header-coin-available-warning {
  background-color: #A16207;
}

.header-slot-available-error,
.header-coin-available-error {
  background-color: #B91C1C;
}

.dashboard-overview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.summary-item {
  position: relative;
  padding: 24px 25px;
  width: 100%;
  border: 1px solid #E5E5E5;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 25px;
  background: #fff;
}

.summary-icon {
  background-color: #FFE5F0;
  color: #FA4094;
  border-radius: 100%;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.summary-icon.icon--selection {
  background-color: #EFF6FF;
  color: #2563EB;
}

.summary-icon.icon--success {
  background-color: #ECFDF5;
  color: #059669;
}

.summary-icon.icon--warning {
  background-color: #FEFCE8;
  color: #EFA100;
}

.summary-category,
.summary-title {
  font-family: 'Poppins', system-ui, Arial, sans-serif;
}

.summary-category {
  font-size: 14px;
  font-weight: 500;
  color: #A1A1AA;
  text-transform: uppercase;
}

.summary-title {
  color: #18181B;
  font-weight: 600;
  font-size: 24px;
  margin-top: 4px;
}

.summary-title.not-available-coins {
  color: #B91C1C;
}

.summary-description {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
}

.summary-description.description--error {
  color: #B91C1C;
}

.summary-description {
  color: #3F3F46;
}

.button.summary-button {
  padding: 7px 16px;
  border: 1px solid #FA4094;
  display: inline-block;
  background-color: var(--background);
  border-radius: 4px;
  color: #FA4094;
  font-family: "Lato", system-ui, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  margin-top: 12px;

}
.button.summary-button:hover {
  background: #FFEEF6;
  color: #FA4094;
}

.button.summary-button.button-coin {
  color: #B91C1C;
  border: 1px solid #B91C1C;
}

.button.summary-button.button-plan {
  color: #B91C1C;
  border: 1px solid #B91C1C;
}

.renew-button,
.add-slot-button {
  align-content: center;
  text-align: center;
  border: 1px solid #B91C1C;
  color: #B91C1C!important;
  border-radius: 4px;
  background-color: #FFF;
  width: 115px;
  display: block;
  height: 32px;
  margin-top: 12px;
}

.renew-button:hover,
.renew-button:active,
.add-slot-button:hover,
.add-slot-button:active {
  background: #FFEEF6;
  color: #B91C1C;
}

.dashboard-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.page-header.dashboard-title-wrapper {
  margin-top: 20px;
}

.dashboard-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.dashboard-title-wrapper .page-title {
  border-bottom: none;
}

.dashboard-buttons a {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
}

.dashboard-post-button {
  padding: 15px 40px;
  background: #FA4094;
  color: white!important;
  transition: 300ms ease-in;
}

.dashboard-post-button:hover,
.dashboard-post-button:active {
  background: #DE116E;
}

.dashboard-manage-button,
.post-job-button {
  border: 1px solid #FA4094;
  border-radius: 4px;
  color: #FA4094!important;
  padding: 14px 16px;
  transition: 300ms ease-in;
}

.dashboard-manage-button:hover,
.dashboard-manage-button:active {
  background: #FFEEF6;
}

.today-todo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #E5E5E5;
  padding: 32px 25px;
  border-radius: 4px;
  background: #FFF;
  flex-wrap: wrap;
  row-gap: 20px;
}

.today-todo-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}

.full-slot-alert {
  background: #FFDEDE;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slot-alert-content {
  width: calc(100vw - 0px);
  padding: 18px 3.5vw;
  display: flex;
  max-width: 1250px;
  gap: 25px;
  justify-content: space-between;
}

.slot-alert-message {
  column-gap: 25px;
  display: grid;
  grid-template-columns: 0.01fr 1fr;
  align-items: center;
}

.slot-alert-message svg {
  grid-row: span 2;
}

.slot-alert-message p,
.slot-alert-message span {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  color: #B91C1C;
}

.slot-alert-message p{
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}

.slot-alert-message span{
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word;
}

body {
  padding: 0;
  padding-top: 120px;
}

.alert-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  column-gap: 25px;
  flex-shrink: 0;
}

.alert-slot-button {
  border: 1px solid#B91C1C;
  color: #B91C1C!important;
  padding: 8px 20px;
  border-radius: 4px;
  text-align: center;
}

.parking-alert-close {
  cursor: pointer;
}

.header-slot-expired {
  padding: 5px 16px;
  display: flex;
  align-items: center;
  color: #FFF!important;
}

.header-slot-expired:hover {
  color: white;
}

.header-slot-expired p{
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.header-slot-expired p span{
  font-weight: 700;
  font-size: 16px;
}

@media(max-width: 1200px) {
  .dashboard-overview {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .slot-alert-content {
    padding: 18px 30px;
  }
}

@media(max-width: 850px) {
  .slot-alert-content {
    grid-template-columns: 1fr 0.5fr;
    padding: 14px 24px;
  }
}

@media(min-width: 768px) {
  .slot-available-mobile {
    display: none!important;
  }

  .modal-medium .modal-content {
    width: 592px;
  }
}

@media(max-width: 768px) {
  .dashboard-overview {
    grid-template-columns: 1fr;
  }

  .summary-item {
    min-height: 170px;
  }

  body {
    padding-top: 55px;
  }
}

/* Modal */
.modal-wrapper {
  padding: 20px;
  overflow-wrap: break-word;
}

.modal-active-title {
  font-family: 'Poppins', system-ui, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.modal-active-desc {
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  color: #333;
}

.modal-active-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.modal-active-buttons button{
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  font-weight: 700;
  font-size: 16px;
}

.modal-active-submit {
  background-color: #FA4094;
  color: #FFF;
}

.modal-active-submit:hover,
.modal-active-submit:active {
  background-color: #DE116E;
}

.modal-active-submit:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.modal-active-cancel {
  background-color: transparent;
  color: #FA4094;
}

.modal-active-cancel:hover,
.modal-active-cancel:active {
  background-color: #FFEEF6;
  color: #FA4094;
}

.modal-active-cancel:disabled {
  opacity: 0.4;
}

.menu-dropdown {
  display: none!important;
}

.menu-dropdown a{
  display: flex!important;
  cursor: pointer;
  color: #BEC5D5;
  font-size: 13px;
  font-weight: 400;
  padding: 10px 0;
  text-transform: uppercase;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  position: relative
}

.menu-dropdown ul {
  display: none;
  list-style-type: none;
  background: #FFF;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  width: 180px;
}

.menu-dropdown ul > li > a {
  color: #18181B;
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 12px 16px;
  transition: 300ms ease-in;
}

.menu-dropdown ul > li > a:hover {
  color: #FA4094;
}

.mobile-slot-available {
  border-radius: 0px;
}

.plan-slot-expired {
  display: none!important;
}

.mobile-slot-available a {
  display: flex!important;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
  border-radius: 0px;
  width: 100%;
  color: white!important;
  background-color: #B91C1C;
}

.mobile-slot-available a:hover {
  background-color: #B91C1C!important;
}

@media(max-width: 1100px) {
  .menu-hide {
    display: none!important;
  }

  .menu-dropdown {
    display: block!important;
  }
}

@media(max-width: 767px) {
  .full-slot-alert {
    margin-top: 8px;
  }

  .mobile-slot-available {
    display: flex!important;
  }
}

@media(max-width: 680px) {
  .full-slot-alert {
    position: relative;
    margin-top: 8px;
  }

  .slot-alert-content {
    flex-direction: column;
    row-gap: 15px;
  }

  .parking-alert-close {
    position: absolute;
    top: 15px;
  }

  .slot-alert-message{
    row-gap: 4px;
    column-gap: 10px;
  }

  .slot-alert-message svg{
    grid-row: span 1;
  }

  .slot-alert-message span{
    grid-column: span 2;
  }

  .slot-alert-content p{
    display: inline-block;
    margin-right: 20px;
  }
}

@media (max-width: 568px) {
  .dashboard-title-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard-buttons,
  .dashboard-buttons a{
    width: 100%;
    text-align: center;
  }
}

@media(max-width: 468px) {
  .dashboard-buttons {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .dashboard-buttons a{
    padding: 14px 16px;
  }

  .modal-active-desc,
  .modal-active-title {
    text-align: center;
  }

  .modal-active-buttons {
    flex-direction: column-reverse;
  }

  .modal-active-submit {
    width: 100%;
  }

  .post-job-button {
    width: 100%;
    text-align: center;
  }

}

@media(max-width: 365px) {
  .summary-svg-wrapper {
    display: none;
  }
}

/*-----------------------------------------------------------------------------------*/
/*	Headed - Available Coins
/*-----------------------------------------------------------------------------------*/
.header-coins-slot-wrapper {
  display: flex;
  justify-content: flex-end;
}

.header-slot-available,
.header-plan-available {
  display: flex;
  align-items: center;
  column-gap: 16px;
  padding: 7px 16px;
  border-radius: 4px 0px 0px 0px;
  color: #FFF;
}

.header-slot-available-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #FFF;
}

.header-slot-expire-info,
.header-coin-expired-info {
  font-family: 'Lato', system-ui, Arial, sans-serif;
  font-weight: 400;
  color: #FFF;
}

.header-credits-available {
  font-size: 16px;
  font-weight: 700;
  color: #FFF!important;
}

.header-slot-expire-info {
  font-size: 12px;
}

.header-coin-expired-info {
  font-size: 14px;
  text-decoration: underline;
}

.header-coin-expired-info:hover {
  color: #FFF;
}

.header-coin-available {
  height: 100%;
  margin-right: -5px;
}

.header-coin-plan-expired {
  display: flex;
  align-items: center;
  column-gap: 16px;
  cursor: pointer;
}

@media(max-width: 767px) {
  .header-coin-available {
    padding: 0px;
  }

  .coins-balance-widget {
    padding: 12px 16px;
    border-radius: 0px;
  }

  .coinBalanceWidget {
    width: 100%;
  }

  .header-slot-available, .header-plan-available,
  .header-coin-plan-expired {
    column-gap: 8px;
  }
  
}

/* Tour Tip  for activity log*/
.menu-setting {
  position: relative;
}

.tour-tip-3 {
  position: absolute;
  padding: 16px;
  background-color: #3F3F46;
  border-radius: 8px;
  max-width: 341px;
  width: 341px;
  height: 90px;
  display: none;
  top: 50px;
  left: -130px;
}

/*-----------------------------------------------------------------------------------*/
/*	Dashboard Sidebar Layout
/*-----------------------------------------------------------------------------------*/

body.dashboard > main {
  display: flex;
  overflow: hidden;
  height: 100%;
}

body.dashboard > main .sidebar {
  color: #91969d;
  background-color: #303e4c;
  display: none;
  flex-direction: column;
  overflow: auto;
  padding: 0;
  width: 250px;
}

body.dashboard > main .sidebar .sidebar-title {
  display: flex;
  align-items: center;
  gap: 1em;
  color: var(--color-neutral-400);
  font-family: var(--font-heading);
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  padding: 12px 16px 8px 16px;
  position: relative;
  margin: 0px;
}

body.dashboard > main .sidebar .sidebar-menu {
  margin: 0px;
  font-size: 14px;
  list-style: none;
  padding: 0px;
}

body.dashboard > main .sidebar .sidebar-menu li > a {
  color: var(--color-white);
  display: flex;
  align-items: center;
  padding: 12px 16px;
}

body.dashboard > main .sidebar .sidebar-menu li > a:hover {
  color: var(--color-white);
  background-color: #FE8AC7;
}

body.dashboard > main .sidebar .sidebar-menu > li.active > a {
  background-color: #1E659B;
}

body.dashboard > main .sidebar .sidebar-menu > li > ul {
  list-style: none;
  padding: 0px;
}

body.dashboard > main .sidebar .sidebar-menu > li > ul > li > a {
  padding-left: 24px;
}

body.dashboard > main .sidebar .sidebar-menu > li > ul > li.active > a {
  color: #FE8AC7;
}

body.dashboard > main .sidebar .sidebar-menu + .sidebar-title::before {
  content: '';
  background-color: var(--color-neutral-50);
  position: absolute;
  opacity: 0.1;
  left: 0px;
  top: 0px;
  height: 1px;
  width: 100%;
}

body.dashboard > main > .content {
  overflow: auto;
  position: relative;
  width: 100%;
}

body.dashboard > main > .content.legacy {
  padding: 0px 16px;
}

@media (min-width: 768px) {
  body.dashboard > main .sidebar {
    display: flex;
    max-width: 260px;
    min-width: 200px;
    width: 33.33%;
  }
}

/* SIDEBAR SETTINGS FOR MOBILE VIEW */
.settings-sidebar-m {
  position: absolute;
  background: #fff;
  z-index: 21;
  width: 100%;
  height: 100vh;
  padding-top: 10px;
  display: none;
}

.settings-sidebar-m-breadcrumb {
  display: inline-flex;
  padding: 0 12px;
  align-items: center;

  ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;

    li {
      display: flex;
      font-size: 12px;
      height: 48px;
      gap: 4px;
      align-items: center;

      a {
        color: #18181B !important;
      }
    }
  }
}

.settings-sidebar-m-body {
  padding: 0 10px;
}

.settings-sidebar-m-title {
  color: #18181B;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}

.settings-sidebar-m-menu {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    color: #71717A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      width: 100%;
      height: 48px;
      padding: 8px 0 8px 0;
      align-items: center;
      gap: 12px;
      justify-content: space-between;

      a {
        color: #18181B !important;
      }
    }
  }
}

a.navbar-brand img.logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -40%);
}

#viewRolePermissionsLink {
  font-weight: bold;
  font-family: 'Lato', sans-serif; 
  text-decoration: none;
  position: relative;
  color: #A0AEEE;
  &:hover {
    color:#ffffff;
  }
}

#viewRolePermissionsLink::after {
  content: ""; 
  display: block;
  width: 100%; 
  height: 1px;
  background: currentColor; 
  position: absolute;
  bottom: -1px;
  left: 0;
}

.captcha-error {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #DC2626;
  font-size: 14px;
}

.exist-brn-in-system-error {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #a94442;
  font-size: 14px;
}

.exist-brn-in-system-error a {
  color: #1D4ED8;
  font-weight: 700;
}

.dashboard-overview .summary-description  {
  display: flex;
  width: 100%;
}

.dropdown-menu li .label-en,
.label-zh {
  padding: 0px 6px;
}

.partner-post {
  margin-top: 10px; 
  margin-left: 10px; 
  display: flex;
  height: 41px;
  align-items: center;
}

.partner-post .glyphicon.glyphicon-edit {
  top: 0;
}